@import "@angular/cdk/overlay-prebuilt.css"; /* ng-gallery styling */

@tailwind base;
@tailwind components;
@tailwind utilities;

/* oswald-200 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Oswald";
  font-style: normal;
  font-weight: 200;
  src: url("assets/fonts/oswald/oswald-v53-latin-200.eot"); /* IE9 Compat Modes */
  src:
    url("assets/fonts/oswald/oswald-v53-latin-200.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("assets/fonts/oswald/oswald-v53-latin-200.woff2")
      format("woff2"),
    /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
      url("assets/fonts/oswald/oswald-v53-latin-200.woff") format("woff"),
    /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+, iOS 5+ */
      url("assets/fonts/oswald/oswald-v53-latin-200.ttf") format("truetype"),
    /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
      url("assets/fonts/oswald/oswald-v53-latin-200.svg#Oswald") format("svg"); /* Legacy iOS */
}

/* oswald-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Oswald";
  font-style: normal;
  font-weight: 400;
  src: url("assets/fonts/oswald/oswald-v53-latin-regular.eot"); /* IE9 Compat Modes */
  src:
    url("assets/fonts/oswald/oswald-v53-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("assets/fonts/oswald/oswald-v53-latin-regular.woff2")
      format("woff2"),
    /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
      url("assets/fonts/oswald/oswald-v53-latin-regular.woff") format("woff"),
    /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+, iOS 5+ */
      url("assets/fonts/oswald/oswald-v53-latin-regular.ttf") format("truetype"),
    /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
      url("assets/fonts/oswald/oswald-v53-latin-regular.svg#Oswald")
      format("svg"); /* Legacy iOS */
}

/* oswald-600 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Oswald";
  font-style: normal;
  font-weight: 600;
  src: url("assets/fonts/oswald/oswald-v53-latin-600.eot"); /* IE9 Compat Modes */
  src:
    url("assets/fonts/oswald/oswald-v53-latin-600.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("assets/fonts/oswald/oswald-v53-latin-600.woff2")
      format("woff2"),
    /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
      url("assets/fonts/oswald/oswald-v53-latin-600.woff") format("woff"),
    /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+, iOS 5+ */
      url("assets/fonts/oswald/oswald-v53-latin-600.ttf") format("truetype"),
    /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
      url("assets/fonts/oswald/oswald-v53-latin-600.svg#Oswald") format("svg"); /* Legacy iOS */
}

/* oswald-700 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Oswald";
  font-style: normal;
  font-weight: 700;
  src: url("assets/fonts/oswald/oswald-v53-latin-700.eot"); /* IE9 Compat Modes */
  src:
    url("assets/fonts/oswald/oswald-v53-latin-700.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("assets/fonts/oswald/oswald-v53-latin-700.woff2")
      format("woff2"),
    /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
      url("assets/fonts/oswald/oswald-v53-latin-700.woff") format("woff"),
    /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+, iOS 5+ */
      url("assets/fonts/oswald/oswald-v53-latin-700.ttf") format("truetype"),
    /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
      url("assets/fonts/oswald/oswald-v53-latin-700.svg#Oswald") format("svg"); /* Legacy iOS */
}

@font-face {
  font-family: "FutoSans";
  src: url("assets/fonts/futo/FutoSans-Black.eot");
  src:
    url("assets/fonts/futo/FutoSans-Black.eot?#iefix")
      format("embedded-opentype"),
    url("assets/fonts/futo/FutoSans-Black.woff2") format("woff2"),
    url("assets/fonts/futo/FutoSans-Black.woff") format("woff"),
    url("assets/fonts/futo/FutoSans-Black.ttf") format("truetype"),
    url("assets/fonts/futo/FutoSans-Black.svg#FutoSans-Black") format("svg");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

.clip-path-0 {
  clip-path: polygon(3% 12%, 100% 0%, 89% 89%, 0% 80%);
}

.clip-path-1 {
  clip-path: polygon(5% 10%, 95% 0%, 100% 100%, 0% 90%);
}

.clip-path-2 {
  clip-path: polygon(15% 1%, 84% 1%, 95% 95%, 6% 84%);
}

.clip-path-3 {
  clip-path: polygon(7% 18%, 87% 1%, 98% 82%, 0% 94%);
}

.clip-path-4 {
  clip-path: polygon(19% 0%, 80% 8%, 100% 90%, 2% 95%);
}

.clip-path-5 {
  clip-path: polygon(13% 2%, 100% 0%, 100% 100%, 0% 98%);
}

.clip-path-6 {
  clip-path: polygon(2% 5%, 94% 17%, 86% 96%, 0% 91%);
}

.clip-path-7 {
  clip-path: polygon(6% 4%, 88% 8%, 95% 91%, 11% 82%);
}

.clip-path-8 {
  clip-path: polygon(0% 10%, 92% 6%, 97% 94%, 11% 91%);
}

.clip-path-9 {
  clip-path: polygon(1% 18%, 96% 8%, 83% 90%, 18% 98%);
}

.clip-path-10 {
  clip-path: polygon(10% 0%, 93% 5%, 89% 88%, 2% 99%);
}

.clip-path-11 {
  clip-path: polygon(0% 0%, 85% 8%, 100% 97%, 2% 85%);
}

.clip-path-12 {
  clip-path: polygon(10% 6%, 84% 3%, 99% 92%, 9% 96%);
}

.clip-path-13 {
  clip-path: polygon(0% 17%, 98% 8%, 98% 91%, 9% 81%);
}

.clip-path-14 {
  clip-path: polygon(14% 8%, 91% 12%, 88% 91%, 10% 99%);
}

.clip-path-15 {
  clip-path: polygon(3% 0%, 86% 0%, 100% 86%, 17% 96%);
}

.clip-path-16 {
  clip-path: polygon(9% 9%, 92% 10%, 95% 98%, 6% 100%);
}

.clip-path-17 {
  clip-path: polygon(13% 16%, 94% 9%, 96% 84%, 14% 83%);
}

.clip-path-18 {
  clip-path: polygon(2% 13%, 81% 1%, 83% 83%, 7% 92%);
}

.clip-path-19 {
  clip-path: polygon(4% 5%, 99% 14%, 90% 97%, 2% 99%);
}

@keyframes enter {
  0% {
    opacity: 0;
    transform: translateY(-50px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes exit {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  100% {
    opacity: 0;
    transform: scale(0.6);
  }
}

::view-transition-old(root) {
  animation: exit 2s ease 0s 1 normal forwards;
}

::view-transition-new(root) {
  animation: enter 3s ease 0s 1 normal forwards;
}
